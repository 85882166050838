import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Space, DatePicker,TimePicker,Dropdown,Menu,Select } from "antd";
import dayjs from 'dayjs';
import { useNavigate } from "react-router";
import "./ComplianceAnalyst.scss";
import { UserOutlined ,ExportOutlined,FilePdfOutlined,FileExcelOutlined} from "@ant-design/icons";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import * as Yup from "yup";

import Loader from "../Loader/Loader";
import * as XLSX from 'xlsx';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';  // Import the timezone plugin
import utc from 'dayjs/plugin/utc';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import {dateFormat} from '../../Helpers/utils'
dayjs.extend(customParseFormat);
dayjs.extend(utc);  // Extend dayjs with the utc plugin
dayjs.extend(timezone);
const ComplianceAnalyst = () => {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);


  const [dataSource, setdataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userData, setuserData] = useState({});

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You’ll get the total count of records from your API response
  });

  const [userToToggle, setUserToToggle] = useState(null);


  const [imagePreview, setImagePreview] = useState("");
  const showModal = (data) => {
    setSelectedRow(data);
    setOpen(true);

  };

  const items = [
    {
      label: (
        <Space>
          <FileExcelOutlined  />
          <span>Export CSV</span>
        </Space>
      ),
      key: "Export",
      onClick: () => exportExcel('Export'), // Define export function
    },
    {
      label: (
        <Space>
          <FileExcelOutlined  />
          <span>Export All CSV</span>
        </Space>
      ),
      key: "ExportAll",
      onClick: () => exportExcel('ExportAll'), // Define export all function
    },
    {
      label: (
        <Space>
          <FilePdfOutlined />
          <span>Export PDF</span>
        </Space>
      ),
      key: "Export PDF",
      onClick: () => exportPDF('Export'), // Define export function
    },
    {
      label: (
        <Space>
          <FilePdfOutlined />
          <span>Export All PDF</span>
        </Space>
      ),
      key: "ExportAllPDF",
      onClick: () => exportPDF('ExportAll'), // Define export all function
    },





  ];




  const closeModal = () => {

    setOpen(false);
    setImagePreview("");
  };




  // const items = [

  //   {
  //     label: (
  //       <Space>
  //         {/* <CheckOutlined /> */}
  //         <span>Pass</span>
  //       </Space>
  //     ),
  //     key: "pass",
  //     onClick: () => assign("pass"), // Navigate to home
  //   },
  //   {
  //     type: "divider",
  //   },
  //   {
  //     label: (
  //       <Space>
  //         {/* <CloseOutlined /> */}
  //         <span>Fail</span>
  //       </Space>
  //     ),
  //     key: "fail",
  //     onClick: () => assign("fail"), // Navigate to home
  //   },
  //   {
  //     label: (
  //       <Space>
  //         {/* <CloseOutlined /> */}
  //         <span>Validation In Progress</span>
  //       </Space>
  //     ),
  //     key: "validation in progress",
  //     onClick: () => assign("validation in progress"), // Navigate to home
  //   },





  // ];



  const capitalizeFirstLetter =(str)=>{
    if (!str) return str;  // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const assign = async (role) => {
    try {
      setloading(true);
      const response = await Axios.put(
        `labtest/changeQualityStatus/${userToToggle.id}`,
        { qualityStatus: role }
      );
      if (response.status === 200) {
        setloading(false);
        toast.success("Assigned status successfully");
        getAppointments(
          { startDate: startValue, endDate: endValue, searchKey: filters.searchKey },
          pagination.current,
          pagination.pageSize
        );

      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };


  const exportExcel=(type) =>{

    if(type != 'Export'){
      ExportAllData(
        { startDate: startValue, endDate: endValue, searchKey: filters.searchKey },
        pagination.current,
        pagination.pageSize
      )
    }else{


      const headers = columns
  .filter((res) => res.key !== 'action')  // Exclude 'action' column
  .map((res) => res.key);  // Get only the keys (name, age, city)

// Step 1: Filter data to only include the columns in `headers`
const filteredData = dataSource.map((row) => {
  let filteredRow = {};
  headers.forEach((key) => {
    filteredRow[key] = row[key];  // Keep only the keys that are in `headers`
  });
  return filteredRow;
});

// Step 2: Replace 'failedAttempts' with 'retake_count' in the headers array
headers.forEach((item, index) => {
  if (item === 'failedAttempts') {
    headers[index] = 'Retake Count'; // Replace 'failedAttempts' with 'retake_count'
  }
  if (item === 'firstName') {
    headers[index] = 'First Name'; // Replace 'failedAttempts' with 'retake_count'
  }
  if (item === 'lastName') {
    headers[index] = 'Last Name'; // Replace 'failedAttempts' with 'retake_count'
  }
  if (item === 'phoneNumber') {
    headers[index] = 'Phone Number';
  }
  if (item === 'quality_status') {
    headers[index] = 'Sample Validation';
  }
  if(item === 'status'){
    headers[index] = 'Finger Printing';
  }
});

// Step 3: Replace 'failedAttempts' with 'retake_count' in the filtered data
filteredData.forEach((row) => {
  if (row.hasOwnProperty('failedAttempts')) {
    row['Retake Count']= row.failedAttempts; // Copy the value to the new key
    delete row.failedAttempts; // Remove the old key
  }if (row.hasOwnProperty('firstName')) {
    row['First Name'] = row.firstName; // Copy the value to the new key
    delete row.firstName; // Remove the old key
  }if (row.hasOwnProperty('phoneNumber')) {
    row['Phone Number'] = row.phoneNumber; // Copy the value to the new key
    delete row.phoneNumber; // Remove the old key
  }if (row.hasOwnProperty('lastName')) {
    row['Last Name'] = row.lastName; // Copy the value to the new key
    delete row.lastName; // Remove the old key
  }if (row.hasOwnProperty('quality_status')) {
    row['Sample Validation'] =capitalizeFirstLetter(row.quality_status); // Copy the value to the new key
    delete row.quality_status; // Remove the old key
  }

  if (row.hasOwnProperty('status') && userData.role === 'compliance analyst') {
    row['Finger Printing'] =capitalizeFirstLetter(row.status); // Copy the value to the new key
    delete row.status; // Remove the old key
  }if (row.hasOwnProperty('location')) {
    row['location'] =capitalizeFirstLetter(row.location); // Copy the value to the new key

  }

});

console.log(filteredData,headers)
const ws = XLSX.utils.json_to_sheet(filteredData, { header: headers });

// Step 4: Apply styling to the header row (bold and uppercase)
const range = XLSX.utils.decode_range(ws['!ref']); // Get the range of cells (e.g., A1:C4)
for (let col = range.s.c; col <= range.e.c; col++) {
  const cellAddress = { r: 0, c: col }; // Row 0 (header row)
  const cellRef = XLSX.utils.encode_cell(cellAddress);

  if (!ws[cellRef]) continue; // Skip if no data in the cell

  // Make the header bold and uppercase
  ws[cellRef].s = {
    font: {
      bold: true,
    },
  };

  // Capitalize header text
  if (ws[cellRef].v) {
    ws[cellRef].v = ws[cellRef].v.toUpperCase();
  }
}

// Step 5: Create a new workbook
const wb = XLSX.utils.book_new();

// Step 6: Append the worksheet to the workbook
XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

// Step 7: Write the Excel file to download
XLSX.writeFile(wb, "Exported_data.xlsx");

    }




  }

  const exportPDF=(type) =>{

    if(type != 'Export'){
      ExportAllDataPDF(
        { startDate: startValue, endDate: endValue, searchKey: filters.searchKey },
        pagination.current,
        pagination.pageSize
      )
    }else{


      const headers = columns
      .filter((res) => res.key !== 'action')  // Exclude 'action' column
      .map((res) => res.key);  // Get only the keys (name, age, city)

    // Step 1: Filter data to only include the columns in `headers`
    const filteredData = dataSource.map((row) => {
      let filteredRow = {};
      headers.forEach((key) => {
        filteredRow[key] = row[key];
        if (key=='status' || key=='location' || key=='quality_status') {
          filteredRow[key] = capitalizeFirstLetter(row[key]); // Copy the value to the new key

            }
         // Keep only the keys that are in `headers`
      });
      return filteredRow;
    });
    console.log(filteredData)
    const tableData = filteredData.map(row => headers.map(header => row[header]));


    console.log(tableData)


    // Step 3: Create a new PDF document
    const doc = new jsPDF({
      unit: 'mm', // Define unit (mm, cm, in, px)
      format: 'a3' // Set page format to A3 (you can also use 'a2', 'letter', 'legal', etc.)
     } )

    // Step 4: Use autoTable to create the table

    headers.forEach((item, index) => {
      if (item === 'failedAttempts') {
        headers[index] = 'Retake Count';
      }
      if (item === 'firstName') {
        headers[index] = 'First Name';
      }
      if (item === 'lastName') {
        headers[index] = 'Last Name';
      }
      if (item === 'phoneNumber') {
        headers[index] = 'Phone Number';
      }
      if (item === 'quality_status') {
        headers[index] = 'Sample Validation';
      }
      if (item === 'status') {
        headers[index] = 'Finger Printing';
      }
    });
    doc.autoTable({
      head: [headers.map(header => header.toUpperCase())],  // Capitalize headers
      body: tableData,
      styles: {
        fontSize: 9,
        font: 'helvetica',
        lineColor: [44, 62, 80],  // Optional: Table border color
        lineWidth: 0.1,
        halign: 'center',  // Center align the text
      },
      headStyles: {
        fillColor: [0, 123, 255], // Header background color
        textColor: [255, 255, 255], // White text color
        fontStyle: 'bold',  // Make header text bold
      },
      columnStyles: {
        // Apply auto width for all columns
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        3: { cellWidth: 'auto' },
        4: { cellWidth: 'auto' },
        5: { cellWidth: 'auto' },
        // Continue for all columns you have
      },
      margin: { top: 20 },
    });

    // Step 5: Save the PDF
    doc.save("Exported_data.pdf");
    }




  }





  const [filters, setFilters] = useState({
    searchKey: "",
    status: undefined,
    quality_status: undefined,
    failedAttempts: undefined,
  });
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);

    if(JSON.parse(localStorage.getItem("userData"))?.data?.user.role != 'compliance analyst'){
      setColumns( columns.filter((res)=> res.title !='Finger Printing'))
    }

    setuserData({
      startDate: startValue,
      endDate: endValue,
      searchKey: filters.searchKey,
      role:data.role
    });
    getAppointments(
      { startDate: startValue, endDate: endValue, searchKey: filters.searchKey },
      pagination.current,
      pagination.pageSize
    );


  }, []);

  const getAppointments = async (data, current, pageSize) => {
    const params = {
      page: current - 1,
      size: pageSize,
      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
      searchKey: data?.searchKey,
      status:data?.status,
      quality_status:data?.quality_status,
      failedAttempts:data?.failedAttempts
    };

    setloading(true);
    try {
      const response = await Axios.get(`labtest/getRecords`, params);
      if (response.status === 200) {
        setloading(false);

        const transformedData = response?.data?.appointments?.map((item) => ({
          ...item,
          date: dateFormat(item.date),
          firstName:item?.patient?.firstName,
          lastName:item?.patient?.lastName,
          phoneNumber:item?.patient?.phoneNumber,
          email:item?.patient?.email,

          key:item?.id
        }));
        console.log(transformedData)
        setdataSource(transformedData);

        setPagination((prop)=>({
          ...prop,
          total: response?.data?.matchingAppointments || 0, // Set total from the response
        }));
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };


  const ExportAllData = async (data, current, pageSize) => {
    const params = {
      page: 0,
      size: 100,
      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
      searchKey: data?.searchKey,
      status:data?.status,
      quality_status:data?.quality_status,
      failedAttempts:data?.failedAttempts
    };

    setloading(true);
    try {
      const response = await Axios.get(`labtest/getRecords`, params);
      if (response.status === 200) {
        setloading(false);

        const transformedData = response?.data?.appointments?.map((item) => ({
          ...item,
          firstName:item?.patient?.firstName,
          lastName:item?.patient?.lastName,
          phoneNumber:item?.patient?.phoneNumber,
          email:item?.patient?.email,

          key:item?.id
        }));



        let headers = columns
        .filter((res) => {
          // If the role is 'compliance analyst', exclude only 'action'
          if (userData.role === 'compliance analyst') {
            return res.key !== 'action'; // Exclude 'action' only
          }
          // Otherwise, exclude both 'action' and 'status'
          return res.key !== 'action' && res.key !== 'status';
        })
        .map((res) => res.key);  // Get only the keys (name, age, city)

      console.log(headers);

      // Step 1: Filter data to only include the columns in `headers`
      const filteredData = transformedData.map((row) => {
        let filteredRow = {};
        headers.forEach((key) => {
          filteredRow[key] = row[key];  // Keep only the keys that are in `headers`
        });
        return filteredRow;
      });

      // Step 2: Replace 'failedAttempts' with 'retake_count' in the headers array
      headers.forEach((item, index) => {
        if (item === 'failedAttempts') {
          headers[index] = 'Retake Count'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'firstName') {
          headers[index] = 'First Name'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'lastName') {
          headers[index] = 'Last Name'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'phoneNumber') {
          headers[index] = 'Phone Number';
        }
        if (item === 'quality_status') {
          headers[index] = 'Sample Validation';
        }
        if(item === 'status'){
          headers[index] = 'Finger Printing';
        }

      });

      // Step 3: Replace 'failedAttempts' with 'retake_count' in the filtered data
      filteredData.forEach((row) => {
        if (row.hasOwnProperty('failedAttempts')) {
          row['Retake Count']= row.failedAttempts; // Copy the value to the new key
          delete row.failedAttempts; // Remove the old key
        }if (row.hasOwnProperty('firstName')) {
          row['First Name'] = row.firstName; // Copy the value to the new key
          delete row.firstName; // Remove the old key
        }if (row.hasOwnProperty('phoneNumber')) {
          row['Phone Number'] = row.phoneNumber; // Copy the value to the new key
          delete row.phoneNumber; // Remove the old key
        }if (row.hasOwnProperty('lastName')) {
          row['Last Name'] = row.lastName; // Copy the value to the new key
          delete row.lastName; // Remove the old key
        }if (row.hasOwnProperty('quality_status')) {
          row['Sample Validation'] =capitalizeFirstLetter(row.quality_status); // Copy the value to the new key
          delete row.quality_status; // Remove the old key
        }

       if (row.hasOwnProperty('location')) {
          row['location'] =capitalizeFirstLetter(row.location); // Copy the value to the new key

        }
        if (row.hasOwnProperty('status') && userData.role === 'compliance analyst') {
          row['Finger Printing'] =capitalizeFirstLetter(row.status); // Copy the value to the new key
          delete row.status; // Remove the old key
        }

      });



      // Convert filtered data to worksheet format
      const ws = XLSX.utils.json_to_sheet(filteredData, { header: headers });

      // Step 4: Apply styling to the header row (bold and uppercase)
      const range = XLSX.utils.decode_range(ws['!ref']); // Get the range of cells (e.g., A1:C4)
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = { r: 0, c: col }; // Row 0 (header row)
        const cellRef = XLSX.utils.encode_cell(cellAddress);

        if (!ws[cellRef]) continue; // Skip if no data in the cell

        // Make the header bold and uppercase
        ws[cellRef].s = {
          font: {
            bold: true,
          },
        };

        // Capitalize header text
        if (ws[cellRef].v) {
          ws[cellRef].v = ws[cellRef].v.toUpperCase();
        }
      }

      // Step 5: Create a new workbook
      const wb = XLSX.utils.book_new();

      // Step 6: Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Step 7: Write the Excel file to download
      XLSX.writeFile(wb, "Exported_data.xlsx");


      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };




  const ExportAllDataPDF = async (data, current, pageSize) => {
    const params = {
      page: 0,
      size: 100,
      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
      searchKey: data?.searchKey,
      status:data?.status,
      quality_status:data?.quality_status,
      failedAttempts:data?.failedAttempts
    };

    setloading(true);
    try {
      const response = await Axios.get(`labtest/getRecords`, params);
      if (response.status === 200) {
        setloading(false);

        const transformedData = response?.data?.appointments?.map((item) => ({
          ...item,
          firstName:item?.patient?.firstName,
          lastName:item?.patient?.lastName,
          phoneNumber:item?.patient?.phoneNumber,
          email:item?.patient?.email,

          key:item?.id
        }));



        const headers = columns
        .filter((res) => res.key !== 'action')  // Exclude 'action' column
        .map((res) => res.key);  // Get only the keys (name, age, city)

      // Step 1: Filter data to only include the columns in `headers`
      const filteredData = transformedData.map((row) => {
        let filteredRow = {};
        headers.forEach((key) => {
          filteredRow[key] = row[key];  // Keep only the keys that are in `headers`
          if (key=='status' || key=='location' || key=='quality_status') {
            filteredRow[key] = capitalizeFirstLetter(row[key]); // Copy the value to the new key

              }
        });
        return filteredRow;
      });

      const tableData = filteredData.map(row => headers.map(header => row[header]));
      headers.forEach((item, index) => {
        if (item === 'failedAttempts') {
          headers[index] = 'Retake Count'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'firstName') {
          headers[index] = 'First Name'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'lastName') {
          headers[index] = 'Last Name'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'phoneNumber') {
          headers[index] = 'Phone Number'; // Replace 'failedAttempts' with 'retake_count'
        }
        if (item === 'quality_status') {
          headers[index] = 'Sample Validation'; // Replace 'failedAttempts' with 'retake_count'
        } if (item === 'status') {
          headers[index] = 'Finger Printing'; // Replace 'failedAttempts' with 'retake_count'
        }
      });

      tableData.forEach((row) => {
        if (row.hasOwnProperty('status') || row.hasOwnProperty('location')) {
          row.status = capitalizeFirstLetter(row.status); // Copy the value to the new key
          row.location = capitalizeFirstLetter(row.location);
        }
      });

      const doc = new jsPDF({
        unit: 'mm', // Define unit (mm, cm, in, px)
        format: 'a3' // Set page format to A3 (you can also use 'a2', 'letter', 'legal', etc.)
       } )


      doc.autoTable({
        head: [headers.map(header => header.toUpperCase())],  // Capitalize headers
        body: tableData,
        styles: {
          fontSize: 9,
          font: 'helvetica',
          lineColor: [44, 62, 80],  // Optional: Table border color
          lineWidth: 0.1,
          halign: 'center',  // Center align the text
        },
        headStyles: {
          fillColor: [0, 123, 255], // Header background color
          textColor: [255, 255, 255], // White text color
          fontStyle: 'bold',  // Make header text bold
        },
        columnStyles: {
          // Apply auto width for all columns
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
          2: { cellWidth: 'auto' },
          3: { cellWidth: 'auto' },
          4: { cellWidth: 'auto' },
          5: { cellWidth: 'auto' },
          // Continue for all columns you have
        },
        margin: { top: 20 },
      });

      // Step 5: Save the PDF
      doc.save("Exported_data.pdf");

      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };


 const [columns , setColumns]= useState([
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: 'center',
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    align: 'center',
    sorter: (a, b) => a.time.localeCompare(b.time),
  },
  {
    title: "Location",
    dataIndex: "location", // Accessing nested patient.location
    key: "location",
    align: 'center',
    sorter: (a, b) => a.location.localeCompare(b.location),

    render: (location) => capitalizeFirstLetter(location),
  },
  {
    title: "First Name",
    dataIndex: "firstName", // Accessing nested patient.firstName
    key: "firstName",
    align: 'center',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (firstName) => firstName, // Display first name
  },
  {
    title: "Last Name",
    dataIndex: "lastName", // Accessing nested lastName
    key: "lastName",
    align: 'center',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    render: (lastName) => lastName, // Display last name
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber", // Accessing nested phoneNumber
    key: "phoneNumber",
    align: 'center',
    sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    render: (phoneNumber) => phoneNumber, // Display phone number
  },
  {
    title: "Email",
    dataIndex: "email", // Accessing nested email
    key: "email",
    align: 'center',
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (email) => email, // Display email
  },

  {
    title: "Sample Validation",
    dataIndex: "quality_status", // Status is not nested, so directly use 'status'
    key: "quality_status",
    align: 'center',
    sorter: (a, b) => a.status.localeCompare(b.quality_status),
    render: (quality_status) => quality_status == 'validation inprogress' ? 'Validation InProgress': capitalizeFirstLetter(quality_status),
  },
  {
    title: "Retake Count",
    dataIndex: "failedAttempts", // Status is not nested, so directly use 'status'
    key: "failedAttempts",
    align: 'center',
    sorter: (a, b) => a?.failedAttempts.toString().localeCompare(b?.failedAttempts.toString()),

  },
  {
    title: "Finger Printing",
    dataIndex: "status", // Status is not nested, so directly use 'status'
    key: "status",
    align: 'center',
    sorter: (a, b) => a.status.localeCompare(b.status),
    // render: (status) => capitalizeFirstLetter(status),
    render: (failedAttempts) => (
      <span style={{ color: failedAttempts == 'fail' ? 'red' : 'inherit' }}>
        {capitalizeFirstLetter(failedAttempts)}
      </span>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: 'center',
    render: (_, record) => (
      <Space>
        <div className="d-flex" style={{textDecoration:'auto'  }}>
          <Button
            className="button-content1"
            onClick={() => {

              showModal(record);

            }}
          >
            <i className="bx bx-show"></i> View Details
          </Button>


          {/* <Button
            type="primary"
            className="button-content"
            onClick={() => {
              setInitialValues({
                id: "",
                name: "",

                date: "",
                time: "",

                status: "",
                event:"",
                testType:"",
                testingStatus:"",
                labTechnician:""
              });
              showEditModal(record);

            }}
          >
            <i className="bx bx-edit"></i> Edit
          </Button> */}


{/* {  (record.testingStatus == 'pending' || record.testingStatus == 'in progress' ) &&  <Dropdown overlay={<Menu items={items} />} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button
                  type="primary"
                  className="button-content role-button"
                  onClick={() => {
                    AssignRoleConfirmation(record);
                  }}
                >
                 Assign Status{" "}
                  <i className="bx bx-chevron-down"></i>{" "}
                </Button>
              </Space>
            </a>
          </Dropdown>} */}
        </div>
      </Space>
    ),
  },
])



  const navigate = useNavigate();

  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 1)
  console.log(currentDate,startDate)

  const [startValue, setStartValue] = useState( dateFormat(startDate));
  const [endValue, setEndValue] = useState( dateFormat(currentDate));
  const [endOpen, setEndOpen] = useState(false);


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };





  const disabledStartDate = (startValue) => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  const onStartChange = (value) => {
    setStartValue(value);
  };

  const onEndChange = (value) => {
    setEndValue(value);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const convertDateFormat = (inputDate) => {
    // Parse the input date string (MM/DD/YY format)
    const date = dayjs(inputDate, 'MM/DD/YYYY');

    // Format the date to 'YYYY-MM-DD'
    const formattedDate = date.format('YYYY-MM-DD');

    return formattedDate;
  };


  const handlePaginationChange = (page, pagesize) => {
    setPagination((prev) => ({ ...prev, current:page, pageSize :pagesize }));
    getAppointments(
      {
        startDate: startValue,
        endDate: endValue,
        searchKey: filters.searchKey,
        status:filters.status,
        quality_status:filters.quality_status,
        failedAttempts:filters?.failedAttempts
      },
      page,
      pagesize
    );
  }



  const handleFilterChange = (key, value) => {
    // Update the filters object dynamically
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value || null, // Set to an empty string if cleared
    }));
  };





  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="heading-content">
          <h2>Finger Printing </h2>
        </div>
        <div className="card custm-card">
          <form className=" form-container " onSubmit={(e)=> e.preventDefault()}>
          <div className="Search-field" >
              <label>Search </label>
              <input
                className="form-control"
                style={{ width: "300px" }}
                placeholder="Search..."
                value={filters.searchKey}
                onChange={(value) =>
                  handleFilterChange("searchKey", value.target.value)
                }
              ></input>
            </div>


            <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>Start Date</label>
                <DatePicker
                  style={{ padding: "8px" }}
                  disabledDate={disabledStartDate}
                  format="MM-DD-YYYY"

                  value={startValue ? dayjs(startValue, 'MM-DD-YYYY') : null}
                  placeholder="MM-DD-YYYY"

                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
                </div>
                <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>End Date</label>
                <DatePicker
                  style={{ padding: "8px" }}
                  disabledDate={disabledEndDate}
                  format="MM-DD-YYYY"
                  value={endValue ? dayjs(endValue, 'MM-DD-YYYY') : null}

                  placeholder="MM-DD-YYYY"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
              </div>
              {userData.role=="compliance analyst" && <div className="Search-field" style={{marginLeft:'9px'}}>
              <label>Finger Printing</label>
                <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.status}
                  options={[
                    { value: "InProgress", label: "InProgress" },
                    { value: "Pass", label: "Pass" },
                    { value: "Fail", label: "Fail" },
                    { value: "No status", label: "No status" },
                    { value: "pending", label: "Pending" },
                  ]}
                  placeholder="Finger Printing"
                  onChange={(value) =>
                    handleFilterChange("status", value)
                  }
                />
</div>}
<div className="Search-field" style={{marginLeft:'9px'}}>
              <label>Sample Validation</label>
                <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.quality_status}

                  options={[
                    { value: "Validation InProgress", label: "Validation InProgress" },
                    { value: "scheduled", label: "Scheduled" },
                    { value: "pass", label: " Pass" },
                    { value: "fail", label: " Fail" },
                    { value: "no status", label: "No status" },

                  ]}



                  placeholder="Sample Validation"
                  onChange={(value) =>
                    handleFilterChange("quality_status", value)
                  } // Update filter state
                />
</div>
<div className="Search-field" style={{marginLeft:'9px'}}>
              <label>Retake Count</label>
                <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.failedAttempts}

                  options={[
                    { value: '0', label: 0 },
                    { value: '1', label: 1 },
                    { value: '2', label:2 },
                    { value: '3', label: 3 },

                  ]}



                  placeholder="Sample Validation"
                  onChange={(value) =>
                    handleFilterChange("failedAttempts", value)
                  } // Update filter state
                />
</div>

          </form>
          <div className="add-button mt-4">
            <Button
              type="primary"
              className="add-user-btn button"
              style={{ marginRight: "5px", padding: "18px" }}
              onClick={() => {
                setPagination({ ...pagination, current: 1 ,pageSize:10});
                getAppointments(
                  {
                    startDate: startValue,
                    endDate: endValue,
                    searchKey: filters.searchKey,
                    status:filters.status,
                    quality_status:filters.quality_status,
                    failedAttempts:filters?.failedAttempts
                  },
                 1,
                 10
                );
              }}
            >
              <i className="bx bx-search"></i> Search
            </Button>
            <Button
              type="primary"
              danger
              className="button1"
              style={{ marginRight: "5px", padding: "18px" }}
              onClick={() => {
                setStartValue(undefined);
                setEndValue(undefined);
                setFilters({
                  searchKey: "",
                  status: undefined,
                  quality_status: undefined,
                  failedAttempts:undefined
                });
                setPagination({ ...pagination, current: 1 ,pageSize:10});
                getAppointments(
                  {
                    failedAttempts:undefined,
                    quality_status:undefined,
                    status:undefined,
                    searchKey: "",
                    startDate: undefined,
                    endDate: undefined,
                  },
                  1,
                  10
                );
              }}
            >
              <i className="bx bx-x"></i>
              Clear
            </Button>
            <Dropdown overlay={<Menu items={items} />} trigger={["click"]}    type="primary"  style={{ marginRight: "50px", padding: "18px" }}    className="custom-dropdown">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    type="primary"
                    className="button-content role-button"

                  >
                    <i className="bx bx-export"></i> Export{" "}
                    <i className="bx bx-chevron-down"></i>{" "}
                  </Button>
                </Space>
              </a>
            </Dropdown>

            {/* <Dropdown overlay={<Menu items={items1} />} trigger={["click"]}    type="primary"  style={{ marginRight: "auto", padding: "18px" }}    className="custom-dropdown">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    type="primary"
                    className="button-content role-button"

                  >
                    <i className="fas fa-file-pdf"></i> Export{" "}
                    <i className="bx bx-chevron-down"></i>{" "}
                  </Button>
                </Space>
              </a>
            </Dropdown> */}
          </div>
        </div>

        <div className="main-contents table-responsive custom-table2">
          <Table
            rowSelection={{
              type: "checkbox",
              preserveSelectedRowKeys: true,
              ...rowSelection,
            }}
            scroll={{ x: 'max-content' }}
            pagination={{
              showSizeChanger: true,
              onChange: handlePaginationChange,

              current: pagination.current,
              pageSize: pagination.pageSize,

              total: pagination.total,
              showTotal: (total, range) => (
                <span>
                  Total {total} records, showing {range[0]} to {range[1]}
                </span>
              ),

            }}


            dataSource={dataSource}
            columns={columns}
            className="table-container custom-table"
            locale={{
              emptyText: <div style={{ textAlign: 'center', color: 'black', fontSize: '16px' }}>No Data Found</div>,
            }}
          />


        </div>



        <Modal
          open={open}
          onCancel={closeModal}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
          width={800}

        >
          <div className="signup-content-model">



          <div className="heading-content">
          <h2>Finger Printing Details</h2>
           </div>
           <div className="row">
           <div className="col-md-6 col-lg-6 col-6 p-4">
              <h5 className="name-patient">Appointment Information</h5>
              <hr className="line-border"/>
              <div className="row mt-3">
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Appointment ID</h5>
                    <p>{selectedRow?.id}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>User</h5>
                    <p>{selectedRow?.firstName }  {selectedRow?.lastName }</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Date</h5>
                    <p>{selectedRow?.date ? selectedRow?.date: 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Time</h5>
                    <p>{selectedRow?.time ? selectedRow?.time: 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Appointment Status</h5>
                    <p>{selectedRow?.testingStatus ? capitalizeFirstLetter(selectedRow?.testingStatus): 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Location</h5>
                    <p>{selectedRow?.location ? capitalizeFirstLetter(selectedRow?.location): 'N/A'}</p>
                  </div>
                </div>

              </div>
              </div>
              <div className="col-md-6 col-lg-6 col-6 p-4">
              <h5 className="name-patient">Finger Printing Report</h5>
              <hr className="line-border"/>
              <div className="row mt-3">
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Test Type</h5>
                    <p>{selectedRow?.testType ? selectedRow?.testType: 'N/A'}</p>
                  </div>
                </div>
                {userData.role =='compliance analyst' && <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                <div className="flex-detail">
                    <h5>Finger Printing</h5>
                    <p>{selectedRow?.status ? capitalizeFirstLetter(selectedRow?.status): 'N/A'}</p>
                  </div>
                </div>}
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                 <div className="flex-detail">
                    <h5>Lab Technician</h5>
                    <p>{selectedRow?.labTechnician ? selectedRow?.labTechnician: 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                 <div className="flex-detail">
                    <h5>Order Id</h5>
                    <p>{selectedRow?.order_id ? selectedRow?.order_id: 'N/A'}</p>
                  </div>
                </div>
              </div>
          </div>
           </div>



          </div>
        </Modal>





      </div>
    </>
  );
};

export default ComplianceAnalyst;
