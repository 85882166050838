

export  const getLogoName =()=>{
   return localStorage.getItem('Logo')
}



export const eventEmitter = new EventTarget();

// Utility function to emit an event
export const emitEvent = (eventName, detail) => {
  const event = new CustomEvent(eventName, {
    detail, // Add event data here
  });
  eventEmitter.dispatchEvent(event); // Emit the event
};

// Utility function to listen for an event
export const listenToEvent = (eventName, callback) => {
  eventEmitter.addEventListener(eventName, callback);
};

// Utility function to remove the listener
export const removeListener = (eventName, callback) => {
  eventEmitter.removeEventListener(eventName, callback);
};


export const dateFormat = (date) => {

  // If the input is a Date object, convert it to a string format "YYYY-MM-DD"
  if (date instanceof Date) {
    date = date.toISOString().split('T')[0]; // Get date portion (YYYY-MM-DD)
  }

  // Ensure the date is in "YYYY-MM-DD" format
  const [year, month, day] = date.split('-');

  // Use UTC methods to ensure that time zone does not affect the date
  const parsedDate = new Date(Date.UTC(year, month - 1, day));

  // Format the date as MM-DD-YYYY
  const monthFormatted = String(parsedDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
  const dayFormatted = String(parsedDate.getUTCDate()).padStart(2, '0');
  const yearFormatted = parsedDate.getUTCFullYear(); // Full year

  return `${monthFormatted}-${dayFormatted}-${yearFormatted}`;
};


export const  allowNumber = (event) => {
    // Allow only digits and update the form field value
    const key = event.key;
    if (!/[0-9]/.test(key)) {
      event.preventDefault(); // Prevent non-numeric input
    }
  };