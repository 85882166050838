import React , { useState, useEffect }from 'react';
import './TopBar.scss';
import { Dropdown, Space, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'; // Import Ant Design icons
import myImage from '../../assests/dummy.png'
import { Link } from "react-router-dom";
import { getLogoName ,listenToEvent, removeListener } from '../../Helpers/utils';
const TopBar = ({ toggleSidebar }) => {
  const [Logo, setLogo] = useState(getLogoName);
  const navigate = useNavigate();

  const items = [
    {
      label: (
        <Space>
          <UserOutlined />
          <span>My-Account</span>
        </Space>
      ),
      key: 'account',
      onClick: () => navigate('/my-account'), // Navigate to user profile
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space>
          <LogoutOutlined />
          <span>Logout</span>
        </Space>
      ),
      key: 'logout',
      onClick: () => logout(), // Navigate to home
    },
  ];

  const logout=()=>{
    localStorage.removeItem('loginTime')
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        navigate('/')
  }
  const [userData, setuserData] = useState({});
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);

    setuserData(data);


  }, []);

  function capitalizeFirstLetter(str) {
    if (!str) return str;  // Check if the string is empty or null
    return str
      .split(' ')  // Split the string by spaces to get an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize the first letter of each word
      .join(' ');  // Join the array back into a string
  }

  useEffect(() => {


    // Add storage event listener
    const handleCustomEvent = (e) => {
      setLogo(getLogoName) // Get the event data from e.detail
    };

    listenToEvent('customEvent', handleCustomEvent); // Listen for the event

    // Cleanup the event listener when ComponentB is unmounted
    return () => {
      removeListener('customEvent', handleCustomEvent); // Remove the listener
    };
  }, []);
  return (
    <div className="home-section" style={{ position: 'sticky', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <div className="home-content">
        <i className='bx bx-menu' onClick={toggleSidebar}></i>
        {/* <img
          className='image-height'
          src="/images/doctor-logo.png"
          style={{ height: "100px" }}
          alt="Logo"
        /> */}
      </div>
      <div className="logo-details">
      <Link to="/my-account" style={{textDecoration:'none'}}>
      <div className="logo-resize">

                    <img
                      src="/images/doctor-logo1.png"
                      style={{ height: "45px" }}
                      alt="Logo"
                    />
                    {/* <h2 class="first-txt" style={{fontSize:'1.5rem'}}>{Logo}</h2> */}

                    </div>
</Link>

      </div>

      <div className="profile-section" style={{display : 'flex'}}>
        <div style={{marginTop: userData.role == 'compliance analyst' ? '7%' : '12%' }}>
        <h6 > <b>{capitalizeFirstLetter(userData.role)} </b></h6>
        </div>

        <div style={{marginLeft : '19px'}}>
        <Dropdown
          overlay={<Menu items={items} />}
          trigger={['click']}
        >
          <div onClick={(e) => e.preventDefault()}>
            <Space>
            <img
          className='profile-img'
          // src={'data:image/jpeg;base64,'+userData.imageData}
          src={myImage}
          alt="Logo"
        />

            </Space>
          </div>
        </Dropdown>
        </div>

      </div>
    </div>
  );
};

export default TopBar;
