import React, { useState, useEffect } from "react";

import {
  Table,
  Button,
  Modal,
  Space,
  DatePicker,
  TimePicker,
  Dropdown,
  Menu,
  Select,
  Input,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import "./Orders.scss";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import * as Yup from "yup";
import moment from "moment";
import Loader from "../Loader/Loader";
import { CloseOutlined, CalendarOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import utc from "dayjs/plugin/utc";
import { dateFormat } from "../../Helpers/utils";
import {allowNumber} from "../../Helpers/utils";
dayjs.extend(customParseFormat);
dayjs.extend(utc); // Extend dayjs with the utc plugin
dayjs.extend(timezone);

const normalizeDate = (date) => {
  // Normalize to UTC and set the time to midnight
  return date ? dayjs(date).tz("UTC").startOf("day") : null;
};
const Orders = () => {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [sameDate,setsameDate] =useState(false)
  const [dataSource, setdataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userData, setuserData] = useState({});

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You’ll get the total count of records from your API response
  });

  const [userToToggle, setUserToToggle] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const showModal = (data) => {
    setSelectedRow(data);
    setOpen(true);
  };

  const showEditModal = (data) => {
    console.log(data);

    let time = data?.time?.split(":");

    let obj = {
      ...data,
      name: data?.firstName + " " + data?.lastName,
      name: data?.firstName + " " + data?.lastName,
      date:data.date ,
      time: time[0] + ":" + time[1],
    };
    // obj.status =  capitalizeFirstLetter(obj.status)
    obj.testingStatus = capitalizeFirstLetter(obj.testingStatus);
    console.log(obj);
    setSelectedRow(obj);
    setEditOpen(true);
  };

  const showDeleteModal = (data) => {
    console.log(data);

    let time = data?.time?.split(":");
    let obj = {
      ...data,
      name: data?.firstName + " " + data?.lastName,
      date: data?.date,
      time: time[0] + ":" + time[1],
    };
    // obj.status =  capitalizeFirstLetter(obj.status)
    obj.testingStatus = capitalizeFirstLetter(obj.testingStatus);
    console.log(obj);
    setSelectedRow(obj);
    setopenDeleteModal(true);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const cancelDeleteToggle = (reset) => {
    setUserToToggle(null);
    setopenDeleteModal(false);
    reset();
    // Close the confirmation modal without any changes
  };

  const closeModal = () => {
    setInitialValues({
      id: "",
      name: "",

      date: "",
      time: "",

      // status: "",
      location: "",
      // testType:"",
      testingStatus: "",
      // labTechnician:"",
      // parameters:""
    });
    setOpen(false);
    setImagePreview("");
  };

  const closeEditModal = () => {
    setInitialValues({
      id: "",
      name: "",

      date: "",
      time: "",

      // status: "",
      location: "",
      // testType:"",
      testingStatus: "",
      // labTechnician:"",
      // parameters:""
    });
    setEditOpen(false);
  };

  const closeDeleteToggle = () => {
    setInitialValues1({
      refund_amount: "",

    });
    setopenDeleteModal(false);
  };


  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userData"))?.data?.user;

    setuserData({
      startDate: startValue,
      endDate: endValue,
      searchKey: filters.searchKey,
      role: userDetail.role,
    });

    if (userDetail.role != "compliance analyst") {
      setColumn(column.filter((res) => res.title != "Lab Test Status"));
    }

    getAppointments(
      { startDate: startValue, endDate: endValue },
      pagination.current,
      pagination.pageSize,
      userDetail.role,
      filters
    );
  }, []);

  const getAppointments = async (data, current, pageSize, role, filter) => {
    const params = {
      page: current - 1,
      size: pageSize,
      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
      ...filter,
      role: role,
    };

    setloading(true);
    try {
      const response = await Axios.get(`appointments/getAppointments`, params);
      if (response.status === 200) {
        setloading(false);

        const transformedData = response?.data?.appointments?.map((item) => ({
          ...item,
          date: dateFormat(item.date),
          firstName: item?.patient?.firstName,
          lastName: item?.patient?.lastName,
          phoneNumber: item?.patient?.phoneNumber,
          email: item?.patient?.email,

          key: item?.id,
        }));

        console.log(transformedData);
        setdataSource(transformedData);

        setPagination((prop) => ({
          ...prop,
          total: response?.data?.matchingAppointments || 0, // Set total from the response
        }));
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const validationSchema1 = Yup.object({
    refund_amount: Yup.number().required("Please Enter the Valid Amount *"),
  });



  const deleteUserConfirmation = (data) => {
    setUserToToggle(data);
    setopenDeleteModal(true);
  };
  const [column, setColumn] = useState([
      {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      align: "center",
      sorter: (a, b) => a.order_id.localeCompare(b.order_id),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      sorter: (a, b) => a.time.localeCompare(b.time),
    },
    {
      title: "Location",
      dataIndex: "location", // Accessing nested patient.location
      key: "location",
      align: "center",
      sorter: (a, b) => a.location.localeCompare(b.location),

      render: (location) => capitalizeFirstLetter(location),
    },
    {
      title: "First Name",
      dataIndex: "firstName", // Accessing nested patient.firstName
      key: "firstName",
      align: "center",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (firstName) => firstName, // Display first name
    },
    {
      title: "Last Name",
      dataIndex: "lastName", // Accessing nested lastName
      key: "lastName",
      align: "center",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (lastName) => lastName, // Display last name
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber", // Accessing nested phoneNumber
      key: "phoneNumber",
      align: "center",
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      render: (phoneNumber) => phoneNumber, // Display phone number
    },
    {
      title: "Email Id",
      dataIndex: "email", // Accessing nested email
      key: "email",
      align: "center",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (email) => email, // Display email
    },

    // {
    //   title: "Appointment Status",
    //   dataIndex: "testingStatus", // Status is not nested, so directly use 'status'
    //   key: "testingStatus",
    //   align: "center",
    //   sorter: (a, b) => a.testingStatus.localeCompare(b.testingStatus),
    //   render: (testingStatus) => capitalizeFirstLetter(testingStatus), // Display status
    // },
    {
      title: "Payment Status",
      dataIndex: "payment_status", // Status is not nested, so directly use 'status'
      key: "payment_status",
      align: "center",
      sorter: (a, b) => a.payment_status.localeCompare(b.payment_status),
      render: (payment_status) => capitalizeFirstLetter(payment_status), // Display status
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space>
          <Link className="d-flex" style={{ textDecoration: "auto" }}>
            <Button
              className="button-content1"
              onClick={() => {
                showModal(record);
              }}
            >
              <i className="bx bx-show"></i> View Details
            </Button>

            {/* <Button
              type="primary"
              className="button-content"
              disabled={
                record.testingStatus == "cancelled" ||
                record.testingStatus == "completed"
              }
              onClick={() => {
                setInitialValues({
                  id: "",
                  name: "",

                  date: "",
                  time: "",

                  // status: "",
                  location: "",
                  // testType:"",
                  testingStatus: "",
                  // labTechnician:"",
                  // parameters:""
                });
                showEditModal(record);
              }}
            >
              <i className="bx bx-edit"></i> Edit
            </Button> */}

          {userData.role != 'compliance analyst' &&  <Button
            type="primary"
            className="button-content"
            disabled={ record.payment_status == 'refund'}
            onClick={() => {
              setInitialValues({
                id: "",
                name: "",

                date: "",
                time: "",

                // status: "",
                location:"",
                // testType:"",
                testingStatus:"",
                // labTechnician:"",
                // parameters:""
              });
              showDeleteModal(record);

            }}
          >
            <i className="bx bx-edit"></i> Refund
          </Button>}

            {/* {  (record.quality_status == 'pass' && record.testingStatus != 'completed' && userData.role == 'compliance analyst') &&  <Dropdown overlay={<Menu items={items} />} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button
                  type="primary"
                  className="button-content role-button"
                  onClick={() => {
                    AssignRoleConfirmation(record);
                  }}
                >
                 Assign Lab Test Status{" "}
                  <i className="bx bx-chevron-down"></i>{" "}
                </Button>
              </Space>
            </a>
          </Dropdown>} */}
          </Link>
        </Space>
      ),
    },
  ]);

  const AssignRoleConfirmation = (data) => {
    setUserToToggle(data);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const handleCancel = (resetForm) => {
    console.log("lll");
    resetForm(); // Reset the form
    setEditOpen(false); // Close the modal
  };

  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    id: "",

    name: "",

    date: "",

    time: "",

    location: "",
    // testType:'',

    testingStatus: "",
    // labTechnician:'',
    // parameters:""
  });
  const [initialValues1, setInitialValues1] = useState({
    refund_amount: "",
    invoice_id: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    // .required("Email is required *")
    name: Yup.string(),
    // .required("Name is required *")
    date: Yup.mixed(),

    time: Yup.string(),

    // .required("Time  is required *")
    // status: Yup.string()

    // .required("status  is required *")
    // ,

    location: Yup.string()
    .required("Event  is required *"),
    // testType: Yup.string()

    // .required("Testing Type is required *")
    // ,
    testingStatus: Yup.string(),

    // .required("Testing Status is required *")
    // labTechnician: Yup.string()

    // .required("LabTechnician is required *")
    // ,
    // parameters: Yup.string()
  });

  const items = [
    {
      label: (
        <Space>
          {/* <CheckOutlined /> */}
          <span>Pass</span>
        </Space>
      ),
      key: "pass",
      onClick: () => assign("pass"), // Navigate to home
    },
    {
      type: "divider",
    },
    {
      label: (
        <Space>
          {/* <CloseOutlined /> */}
          <span>Fail</span>
        </Space>
      ),
      key: "fail",
      onClick: () => assign("fail"), // Navigate to home
    },
  ];

  const assign = async (role) => {
    try {
      setloading(true);
      const response = await Axios.put(
        `labtest/changeLabTestStatus/${userToToggle.id}`,
        { labTestStatus: role }
      );
      if (response.status === 200) {
        setloading(false);
        toast.success("Assigned status successfully");
        getAppointments(
          { startDate: startValue, endDate: endValue },
          pagination.current,
          pagination.pageSize,
          userData.role,
          filters
        );
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const disabledStartDate = (startValue) => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  const onStartChange = (value) => {
    setStartValue(value);
  };

  const onEndChange = (value) => {
    setEndValue(value);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const convertDateFormat = (inputDate) => {
    // Parse the input date string (MM/DD/YY format)
    const date = dayjs(inputDate, "MM/DD/YYYY");

    // Format the date to 'YYYY-MM-DD'
    const formattedDate = date.format("YYYY-MM-DD");

    return formattedDate;
  };

  const handlePaginationChange = (page, pagesize) => {
    console.log("asd", page, pagesize);
    setPagination((prev) => ({ ...prev, current: page, pageSize: pagesize }));
    getAppointments(
      {
        startDate: startValue,
        endDate: endValue,
      },
      page,
      pagesize,
      userData.role,
      filters
    );
  };

  const refundAmount = async (values, resetForm) => {
    try {
      setloading(true);
      let response = await Axios.put(
        `appointments/changePaymentStatus/${selectedRow.id}?refund_amount=${values.refund_amount}`,
        {}
      );
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
        toast.success("Refund Initiated Successfully");
        getAppointments(
          { startDate: startValue, endDate: endValue },
          pagination.current,
          pagination.pageSize,
          userData.role,
          filters
        );
        setloading(false);
        resetForm();
        cancelDeleteToggle();
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const [filters, setFilters] = useState({
    searchKey: "",
    location: undefined,
    // testingStatus: undefined,
    payment_status: undefined,
  });

  const handleSubmit = async (value, reset) => {
    console.log(value);
    const obj = {
      date: convertDateFormat(value.date),
      // time:dayjs(value.time ).local().format('HH:mm'),
      time: value.time,
      location: value.location,
      // parameters:value.parameters
    };

    try {
      setloading(true);

      let response = await Axios.put(
        `appointments/editAppointment/${value.id}`,
        obj
      );
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
        toast.success("Edit Successfully");
        getAppointments(
          { startDate: startValue, endDate: endValue },
          pagination.current,
          pagination.pageSize,
          userData.role,
          filters
        );
        setloading(false);
        navigate("/appointments");
        closeEditModal();

        reset();
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error(error);
    }
  };
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 1);
  console.log(currentDate, startDate);

  const [startValue, setStartValue] = useState(dateFormat(startDate));
  const [endValue, setEndValue] = useState(dateFormat(currentDate));
  const [endOpen, setEndOpen] = useState(false);

  const handleFilterChange = (key, value) => {
    // Update the filters object dynamically
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value || null, // Set to an empty string if cleared
    }));
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="heading-content">
          <h2>Orders</h2>
        </div>
        <div className="card custm-card">
          <form className=" form-container ">
            <div className="Search-field">
              <label>Search </label>
              <input
                className="form-control"
                style={{ width: "300px" }}
                placeholder="Search..."
                value={filters.searchKey}
                onChange={(value) =>
                  handleFilterChange("searchKey", value.target.value)
                }
              ></input>
            </div>

            <div className="Search-field" style={{ marginLeft: "10px" }}>
              <label>Start Date</label>
              <DatePicker
                style={{ padding: "8px" }}
                disabledDate={disabledStartDate}
                format="MM-DD-YYYY"
                value={startValue ? dayjs(startValue, "MM-DD-YYYY") : null}
                placeholder="MM-DD-YYYY"
                onChange={onStartChange}
                onOpenChange={handleStartOpenChange}
              />
            </div>
            <div className="Search-field" style={{ marginLeft: "10px" }}>
              <label>End Date</label>
              <DatePicker
                style={{ padding: "8px" }}
                disabledDate={disabledEndDate}
                format="MM-DD-YYYY"
                value={endValue ? dayjs(endValue, "MM-DD-YYYY") : null}
                placeholder="MM-DD-YYYY"
                onChange={onEndChange}
                open={endOpen}
                onOpenChange={handleEndOpenChange}
              />
            </div>

            <div className="Search-field" style={{ marginLeft: "10px" }}>
              <label>Location</label>
              <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.location}
                options={[
                  { value: "delhi", label: "Delhi" },
                  { value: "pune", label: "Pune" },
                ]}
                placeholder="Location"
                onChange={(value) => handleFilterChange("location", value)}
              />
            </div>

            {/* <div className="Search-field" style={{ marginLeft: "10px" }}>
              <label>Appointment Status</label>
              <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.testingStatus}
                options={[
                  { value: "cancelled", label: "Cancelled" },
                  { value: "completed", label: "Completed" },
                  { value: "in progress", label: "In progress" },
                  { value: "pending", label: "Pending" },
                ]}
                placeholder="Appointment Status"
                onChange={(value) => handleFilterChange("testingStatus", value)}
              />
            </div> */}
            <div className="Search-field" style={{ marginLeft: "10px" }}>
            <label>Payment Status </label>

              <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.payment_status}
                options={[
                  { value: "pending", label: "Pending" },
                  { value: "paid", label: "Paid" },
                  { value: "refund initiated", label: "Refund initiated" },


                ]}
                placeholder="Payment Status"
                onChange={(value) => handleFilterChange("payment_status", value)}
              />
            </div>
          </form>
          <div className="add-button mt-4">
            <Button
              type="primary"
              className="add-user-btn button"
              style={{ marginRight: "5px", padding: "18px" }}
              onClick={() => {
                setPagination({ total: 0, current: 1, pageSize: 10 });
                getAppointments(
                  {
                    startDate: startValue,
                    endDate: endValue,
                  },
                  1,
                  10,
                  userData.role,
                  filters
                );
              }}
            >
              <i className="bx bx-search"></i> Search
            </Button>
            <Button
              type="primary"
              danger
              className="button1"
              style={{ marginRight: "auto", padding: "18px" }}
              onClick={() => {
                setStartValue(undefined);
                setEndValue(undefined);
                setFilters({
                  searchKey: "",
                  location: undefined,
                  // testingStatus: undefined,
                  payment_status:undefined
                });
                setPagination({ ...pagination, current: 1, pageSize: 10 });
                getAppointments(
                  {
                    startDate: undefined,
                    endDate: undefined,
                  },
                  1,
                  10,
                  userData.role,
                  {
                    payment_status:undefined,
                    location: undefined,
                    // testingStatus: undefined,
                    searchKey: "",
                  }
                );
              }}
            >
              <i className="bx bx-x"></i>
              Clear
            </Button>
          </div>
        </div>

        <div className="main-contents table-responsive">
          <Table
            rowSelection={{
              type: "checkbox",
              preserveSelectedRowKeys: true,
              ...rowSelection,
            }}
            scroll={{ x: "max-content" }}
            pagination={{
              showSizeChanger: true,
              onChange: handlePaginationChange,

              current: pagination.current,
              pageSize: pagination.pageSize,

              total: pagination.total,
              showTotal: (total, range) => (
                <span>
                  Total {total} records, showing {range[0]} to {range[1]}
                </span>
              ),
            }}
            dataSource={dataSource}
            columns={column}
            showSizeChanger={true}
            className="table-container custom-table5"
            locale={{
              emptyText: (
                <div
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "16px",
                  }}
                >
                  No Data Found
                </div>
              ),
            }}
          />
        </div>

        <Modal
          open={open}
          onCancel={closeModal}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
          width={800}
        >
          <div className="signup-content-model">
            <div className="heading-content">
              <h2>Order Details</h2>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-12 p-4">
                <h5 className="name-patient">Order Information</h5>
                <hr className="line-border" />
                <div className="row mt-3">
                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>Order ID</h5>
                      <p>{selectedRow?.order_id}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>User</h5>
                      <p>
                        {selectedRow?.firstName} {selectedRow?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>Date</h5>
                      <p>{selectedRow?.date ? selectedRow?.date : "N/A"}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>Time</h5>
                      <p>{selectedRow?.time ? selectedRow?.time : "N/A"}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>Appointment Status</h5>
                      <p>
                        {selectedRow?.testingStatus
                          ? capitalizeFirstLetter(selectedRow?.testingStatus)
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                    <div className="flex-detail">
                      <h5>Location</h5>
                      <p>
                        {selectedRow?.location ? selectedRow?.location : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-6 col-6 p-4">
              <h5 className="name-patient">Lab Test Report</h5>
              <hr className="line-border"/>
              <div className="row mt-3">
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Test Type</h5>
                    <p>{selectedRow?.testType ? selectedRow?.testType: 'N/A'}</p>
                  </div>
                </div>
                {userData.role == 'compliance analyst' && <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Lab Test status</h5>
                    <p>{selectedRow?.status ? capitalizeFirstLetter(selectedRow?.status) : 'N/A'}</p>
                  </div>
                </div>}
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Lab Technician</h5>
                    <p>{selectedRow?.labTechnician ? selectedRow?.labTechnician: 'N/A'}</p>
                  </div>
                </div>
                {/* <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>parameters</h5>
                    <p>{selectedRow?.parameters ? selectedRow?.parameters: 'N/A'}</p>
                  </div>
                </div> *
              </div>
          </div> */}
            </div>
          </div>
        </Modal>

        <Modal
          open={editOpen}
          onCancel={closeEditModal}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
          width={800}
        >
          <div className="signup-content-model">
            <div className="heading-content">
              <h2>Edit Appointment</h2>
            </div>

            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={selectedRow || initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm); // Use your custom submit logic here
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-12 p-4">
                      <h5 className="name-patient">Appointment Information</h5>
                      <hr className="line-border" />

                      <div className="row mt-3">
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>Appointment ID</label>

                              <Field
                                autoComplete="off"
                                className={`form-input ${
                                  errors.id && touched.id ? "error" : ""
                                }`}
                                name="id"
                                placeholder="Appointment ID*"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>User</label>

                              <Field
                                autoComplete="off"
                                className={`form-input ${
                                  errors.name && touched.name ? "error" : ""
                                }`}
                                name="name"
                                placeholder="User*"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>Date</label>
                              <DatePicker
                                value={
                                  values.date
                                    ? dayjs(values.date, "MM-DD-YYYY")
                                    : null
                                } // Ensure that null or undefined value is handled
                                onChange={(date) => {
                                  // If the date is cleared, set the value to null
                                  setFieldValue(
                                    "date",
                                    date ? dayjs(date, "MM-DD-YYYY") : null
                                  );
                                  if (date && dayjs(date).isSame(dayjs(selectedRow.date))) {
                                    setsameDate(true);
                                  } else {
                                    setsameDate(false);
                                  }
                                }}
                                // disabledDate={(current) =>
                                //   current &&
                                //   current.isBefore(
                                //     dayjs(selectedRow.date).startOf("day")
                                //   )
                                // }


                                disabledDate={(current) => {
                                  // Get the selectedRow date and today's date
                                  const selectedDate = dayjs(selectedRow.date).startOf("day");
                                  const today = dayjs().startOf("day");

                                  // Check if selectedRow.date is a past date
                                  const endDate = selectedDate.isBefore(today) ? today.endOf("day") : selectedDate.endOf("day");

                                  // Disable previous dates based on the logic described
                                  return current && current.isBefore(endDate, "day");
                                }}

                                name="date"
                                format="MM-DD-YYYY"
                                placeholder="MM-DD-YYYY"
                                className={`form-input ${
                                  errors.date && touched.date ? "error" : ""
                                }`} // Add error styling
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>Time</label>
                              <TimePicker
                                value={
                                  values.time
                                    ? dayjs(values.time, "HH:mm")
                                    : null
                                } // Ensure that null or undefined value is handled
                                onChange={(date) => {
                                  // If the date is cleared, set the value to null
                                  setFieldValue(
                                    "time",
                                    date ? date.format("HH:mm") : null
                                  );



                                }}
                                name="time"
                                format="HH:mm"
                                className={`form-input ${
                                  errors.time && touched.time ? "error" : ""
                                }`}
                                disabledTime={() => {

                                  if (sameDate) {
                                    const time = selectedRow.time.split(":");
                                    const hours = [];
                                    const minutes = [];

                                    return {
                                      disabledHours: () => {
                                        for (let i = 0; i < time[0]; i++) {
                                          hours.push(i); // Disable all hours before the selected hour
                                        }
                                        return hours;
                                      },
                                      disabledMinutes: (selectedHour) => {
                                        if (selectedHour === time[0] - 1) {
                                          // Disable all minutes before the selected minute for the previous hour
                                          for (let i = 0; i < time[1]; i++) {
                                            minutes.push(i); // Disable minutes before the selected minute
                                          }
                                        }

                                        // Disable all minutes for the selected hour if it is equal to the selected hour
                                        if (selectedHour === time[0]) {
                                          for (let i = 0; i < time[1]; i++) {
                                            minutes.push(i); // Disable minutes up to the selected minute
                                          }
                                        }

                                        return minutes;
                                      },
                                    };
                                  }

                                  // If it's not today, do not disable any time
                                  return {
                                    disabledHours: () => [],
                                    disabledMinutes: () => [],
                                  };
                                }}
                              />
                            </div>
                            {/* <input
                          autoComplete="off"
                          type="time"
                          className={`form-input ${
                            errors.status && touched.status ? "error" : ""
                          }`}
                          name="status"
                          placeholder="Status*"
                        /> */}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>Appointment Status</label>
                              <Field
                                autoComplete="off"
                                className={`form-input ${
                                  errors.testingStatus && touched.testingStatus
                                    ? "error"
                                    : ""
                                }`}
                                name="testingStatus"
                                placeholder="Appointment Status*"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                          <div className="flex-detail">
                            <div className="Search-field">
                              <label>Location</label>

                              <Field
                                autoComplete="off"
                                className={`form-input ${
                                  errors.location && touched.location
                                    ? "error"
                                    : ""
                                }`}
                                name="location"
                                placeholder="Location*"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-6 col-6 p-4">
              <h5 className="name-patient">Lab Test Report</h5>
              <hr className="line-border"/>
              <div className="row mt-3">
               <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                  <div className="Search-field" >
              <label>Test Type</label>
                    <Field
                          autoComplete="off"

                          className={`form-input ${
                            errors.testType && touched.testType ? "error" : ""
                          }`}
                          name="testType"
                          placeholder="Test Type*" disabled
                        />
                        </div>
                  </div>
                </div>
                {userData.role == 'compliance analyst' &&<div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                  <div className="Search-field" >
              <label>Testing status</label>
                  <Field
                          autoComplete="off"

                          className={`form-input ${
                            errors.status && touched.status ? "error" : ""
                          }`}
                          name="status"
                          placeholder="Testing status*" disabled
                        />
  </div>

                  </div>
                </div> }
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                  <div className="Search-field" >
              <label>Lab Technician</label>


                    <Field
                          autoComplete="off"

                          className={`form-input ${
                            errors.labTechnician && touched.labTechnician ? "error" : ""
                          }`}
                          name="labTechnician"
                          placeholder="Lab Technician*" disabled
                        />
                        </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                  <div className="Search-field" >
              <label>Parameters</label>


                    <Field
                          autoComplete="off"

                          className={`form-input ${
                            errors.parameters && touched.parameters ? "error" : ""
                          }`}
                          name="parameters"
                          placeholder="Parameters*"
                        />
                        </div>
                  </div>
                </div>

              </div>
          </div> */}
                  </div>

                  <div
                    className="submit-container1"
                    style={{ marginTop: "20px" }}
                  >
                    <button className="submit-button1 " type="submit">
                      Submit
                    </button>
                    <button
                      className="submit-button2"
                      type="button"
                      onClick={() => handleCancel(resetForm)}
                    >
                      Close
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>

        <Modal
          open={openDeleteModal}
          onCancel={closeDeleteToggle}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className="signup-content-model1">
            <div className="heading-content">
              <h2>Refund</h2>
            </div>

            <Formik
              initialValues={selectedRow ||initialValues1}
              validationSchema={validationSchema1}
              onSubmit={(values, { resetForm }) => {
                refundAmount(values, resetForm);
                console.log(values); // Handle form submission here
              }}
            >
              {({ setFieldValue, values, resetForm, errors, touched }) => (
                <Form>
                  {/* Email Option with Masked Email */}
                  <div className="mb-2 d-flex">
                    <div className="row">

                      <div className="col-6 Search-field form-group">
                      <label>Amount</label>
                      <Field
                      className={`form-input ${
                        errors.refund_amount && touched.refund_amount ? "error" : ""
                      }`}
                      onKeyPress={allowNumber}
                      name="refund_amount"
                      placeholder="Enter Amount*"
                      // onChange={(event) => handleChange(event)}
                    />
                    <ErrorMessage name="refund_amount">
                      {msg => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl" style={{lineHeight: '3.04167em'}}></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                      </div>


                      <div className="col-6  form-group">
                      <label>Invoice No</label>
                      <Field
                      className={`form-input ${
                        errors.invoice_id && touched.invoice_id ? "error" : ""
                      }`}
                      onKeyPress={allowNumber}
                      name="invoice_id"
                      placeholder="Invoice No*"
                      // onChange={(event) => handleChange(event)}
                    />
                    {/* <ErrorMessage name="refund_amount">
                      {msg => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage> */}
                        </div>

                    </div>
                  </div>
                  <div
                    className="submit-container1"
                    style={{ marginTop: "20px" }}
                  >
                    <button className="submit-button1" type="submit">
                      Send
                    </button>
                    <button
                      className="submit-button2"
                      type="button"
                      onClick={() => cancelDeleteToggle(resetForm)}
                    >
                      Close
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Orders;




