import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Switch,
  Modal,
  Dropdown,
  Space,
  Menu,
  Tooltip,Select
} from "antd";
import { useNavigate } from "react-router";
import "./UserManagment.scss";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import * as Yup from "yup";
import { UserOutlined } from "@ant-design/icons";
import Loader from "../Loader/Loader";
const UserManagement = () => {
  const { Option } = Select;
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAprrovalModal, setopenAprrovalModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [type, setType] = useState("Add User");
  const [approvalType, setApprovalType] = useState("");


  const [dataSource, setdataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false); // State to manage confirmation modal visibility
  const [userToToggle, setUserToToggle] = useState(null); //
  const [filteredData, setFilteredData] = useState([]); // State to hold filtered data
  const [searchText, setSearchText] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const showModal = (data) => {
    setSelectedRow(data);
    setOpen(true);
    setType("Edit User");
  };


  const showApprovalModal = (data,type) => {
    setUserToToggle(data);
    setopenAprrovalModal(true);
    setApprovalType(type);
  };

  const deleteUserConfirmation = (data) => {
    setUserToToggle(data);
    setopenDeleteModal(true);
  };

  const items = [
    {
      label: (
        <Space>
          <UserOutlined />
          <span>Admin</span>
        </Space>
      ),
      key: "Admin",
      onClick: () => assign("Admin"), // Navigate to user profile
    },
    {
      type: "divider",
    },
    {
      label: (
        <Space>
          <UserOutlined />
          <span>Contributor</span>
        </Space>
      ),
      key: "Contributor",
      onClick: () => assign("Contributor"), // Navigate to home
    },
    {
      type: "divider",
    },
    {
      label: (
        <Space>
          <UserOutlined />
          <span>Compliance Analyst</span>
        </Space>
      ),
      key: "Compliance Analyst",
      onClick: () => assign("Compliance Analyst"), // Navigate to home
    },





  ];

  const handleCancel = (resetForm) => {
    setImagePreview("");
    resetForm(); // Reset the form
    setOpen(false); // Close the modal
  };

  const closeModal = () => {
    setInitialValues({
      email: "",
      password: "",

      firstName: "",
      lastName: "",

      phoneNumber: "",
      role:"Contributor"
    });
    setOpen(false);
    setImagePreview(""); // Close the modal
  };
  const [userData, setuserData] = useState({});

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);
    setuserData(data);

    getUser(data?.id);
  }, []);

  const assign = async (role) => {
    try {
      setloading(true);
      const response = await Axios.put(
        `manageUsers/changeRole/${userToToggle.id}`,
        { role: role }
      );
      if (response.status === 200) {
        setloading(false);
        toast.success("Assigned role successfully");

        getUser(userData?.id); // Refresh the data
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const getUser = async (id) => {
    console.log("lll", id);
    setloading(true);
    try {
      const response = await Axios.get(`manageUsers/getUsers/${id}`);
      if (response.status === 200) {
        setloading(false);
        setdataSource(response.data);
        setFilteredData(response.data);
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const confirmToggle = async () => {
    try {
      setloading(true);
      const response = await Axios.post(
        `manageUsers/toggleStatus/${userToToggle.id}`,
        {}
      );
      if (response.status === 200) {
        setloading(false);
        toast.success("User status updated successfully");
        setConfirmModalOpen(false); // Close the confirmation modal
        getUser(userData?.id); // Refresh the data
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const cancelToggle = () => {
    setUserToToggle(null);
    setConfirmModalOpen(false);
    getUser(userData?.id); // Close the confirmation modal without any changes
  };
  const cancelDeleteToggle = () => {
    setUserToToggle(null);
    setopenDeleteModal(false);

    // Close the confirmation modal without any changes
  };





  const cancelApprovalToggle = () => {
    setUserToToggle(null);
    setopenAprrovalModal(false);

    // Close the confirmation modal without any changes
  };

  const AssignRoleConfirmation = (data) => {
    setUserToToggle(data);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      align: 'center',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      align: 'center',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: 'center',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: 'center',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: 'center',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Action",
      key: "action",
      align: 'center',

      render: (_, record) => ( record.approved ?
   (<Space>
          <div className="d-flex" style={{textDecoration:'auto'  }}>
            <Button
              type="primary"
              className="button-content"
              onClick={() => {
                setInitialValues({
                  email: "",
                  password: "",

                  firstName: "",
                  lastName: "",

                  phoneNumber: "",
                  role:"Contributor"
                });
                showModal(record);
                setType("Edit User");
              }}
            >
              <i className="bx bx-edit"></i> Edit
            </Button>
            <Button
              type="primary"
              className="button-content"
              onClick={() => {
                deleteUserConfirmation(record);
              }}
            >
              <i className="bx bx-trash"></i> Delete
            </Button>
            <Dropdown overlay={<Menu items={items} />} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    type="primary"
                    className="button-content role-button"
                    onClick={() => {
                      AssignRoleConfirmation(record);
                    }}
                  >
                    <i className="bx bx-user-check"></i> Role{" "}
                    <i className="bx bx-chevron-down"></i>{" "}
                  </Button>
                </Space>
              </a>
            </Dropdown>
          </div>

            <Tooltip title={"Active / Inactive"}>
              <Switch
                checked={record.loginAllowed}
                onChange={(event) => handleToggle(record, event)}
              />
            </Tooltip>

        </Space>) : (
          <Space>
          <Button
              type="primary"
              className="button-content"
              onClick={() => {

                showApprovalModal(record,'accept');

              }}
            >
              <i className="bx bx-check"></i> Accept
            </Button>
            <Button
              type="primary"
              className="button-content"
              onClick={() => {
                showApprovalModal(record,'reject');
              }}
            >
              <i className="bx bx-x"></i> Reject
            </Button>
          </Space>
      )

      ),
    },
  ];

  const deleteUser = async () => {
    try {
      setloading(true);
      let response = await Axios.post(
        `manageUsers/deleteUser/${userToToggle.id}`,
        {}
      );
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
        toast.success("User Deleted Successfully");
        getUser(userData?.id);
        setloading(false);
        cancelDeleteToggle();
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };




  const approvalUser = async () => {
    try {
      setloading(true);
      let response = await Axios.put(
        `manageUsers/changeRequestStatus/${userToToggle?.id}`,
        {"status":approvalType == 'accept' ? 1 : 0}
      );
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
        toast.success(approvalType == 'accept' ? "User Accepted Successfully" : "User Rejected Successfully");
        getUser(userData?.id);
        setloading(false);
        cancelApprovalToggle();
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };




  const handleToggle = (record, event) => {
    console.log(event);
    record.loginAllowed = event;
    setUserToToggle(record); // Set the user whose status we're toggling
    setConfirmModalOpen(true); // Open the confirmation modal
  };

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",


    firstName: "",
    lastName: "",

    phoneNumber: "",
    role:"Contributor"
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),

    firstName: Yup.string()
      .required("First name is required *")
      .min(2, "First name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    lastName: Yup.string()
      .required("Last name is required *")
      .min(2, "Last name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits *") // Matches exactly 10 digits
      .required("Phone number is required *"),
      role: Yup.string()

  });

  const handleSubmit = async (value, reset) => {
    const url =
      type == "Add User" ? "manageUsers/register" : "manageUsers/editUser";
    try {
      setloading(true);


      // delete obj.role
      if (type == "Add User") {
        const obj ={
          email:value.email ,
          password: value.password,

          firstName: value.firstName,
          lastName: value.lastName,

          phoneNumber: value.phoneNumber,
          userRole:{
            "role": value.role
          }
        }
        let response = await Axios.post(url, obj);
        if (response.status == 200) {
          // Checks if status code is 200 (OK)
          toast.success("User Added Successfully");
          getUser(userData?.id);
          setloading(false);
          navigate("/user-management");
          closeModal();

          reset();
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 404
        ) {
          console.log("here");
          toast.error(response.response.data.message);
          setloading(false);
        }
        toast.error(response?.response?.data);
        setloading(false);
      } else {
        let response = await Axios.put(url, value);
        if (response.status == 200) {
          // Checks if status code is 200 (OK)
          toast.success("Record updated successfully");
          getUser(userData?.id);
          navigate("/user-management");
          setloading(false);
          closeModal();
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 404
        ) {
          console.log("here");
          toast.error(response.response.data.message);
          setloading(false);
        } else if (response.status === 422) {
          // Email conflict
          toast.error("Email already exists");
          setloading(false);
        } else if (response.status === 409) {
          // Phone number conflict
          toast.error("Phone number already registered");
          setloading(false);
        }

        // toast.error( response?.response?.data);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const handleSearch = () => {
    const searchValue = searchText.toLowerCase();
    const filteredResults = dataSource.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchValue) ||
        user.lastName.toLowerCase().includes(searchValue) ||
        user.email.toLowerCase().includes(searchValue) ||
        user.phoneNumber.toLowerCase().includes(searchValue)||
        user.role.toLowerCase().includes(searchValue)
    );
    setFilteredData(filteredResults);
  };

  const handleClearSearch = () => {
    setSearchText(""); // Clear the search input
    setFilteredData(dataSource); // Reset to the original data
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      // Convert the uploaded image to base64
      reader.onload = () => {
        setImagePreview(reader.result); // Save base64 image to state
      };

      reader.onerror = (error) => {
        console.error("Error converting file to base64:", error);
      };

      reader.readAsDataURL(file); // Start reading the file
    }
  };
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You’ll get the total count of records from your API response
  });

  const handlePaginationChange = (page, pagesize) => {
    console.log('asd',page, pagesize)
    setPagination((prev) => ({ ...prev, current:page, pageSize :pagesize }));

  }
  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="heading-content">
          <h2>User Management</h2>
        </div>
        <div className="card custm-card">
        <div className="form-container">
          <div className="Search-field1" style={{ marginRight: "18px"  ,marginBottom: '0.5rem' }}>
          <label>Search</label>
            <input
              className="form-control"
              style={{ width: "400px" }}
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            ></input>
          </div>
          <div className="add-button mt-2" style={{marginRight:'auto'}}>
          <Button
            type="primary"
            className="add-user-btn mt-4"
            style={{ marginRight: "5px", padding: "18px" }}
            onClick={handleSearch}
          >
            <i className="bx bx-search"></i> Search
          </Button>
          <Button
           type="primary"
           danger
           className="button1 mt-4"
           style={{ marginRight: "auto", padding: "18px" }}
            onClick={()=>{handleClearSearch();   setPagination({ ...pagination, current: 1 ,pageSize:10})}}
          >
            <i className="bx bx-x"></i>
            Clear
          </Button>
          </div>
          <div className="add-button mt-2">
            <Button
              type="primary"
              className="add-user-btn mt-4"
              style={{ padding: "18px" }}
              onClick={() => {
                showModal();
                setType("Add User");
              }}
            >
              <i className="bx bx-plus"></i> Add User
            </Button>

          </div>
          </div>
        </div>
        <div className="main-contents table-responsive">
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              onChange: handlePaginationChange,

              current: pagination.current,
              pageSize: pagination.pageSize,

              total: pagination.total,
              showTotal: (total, range) => (
                <span>
                  Total {total} records, showing {range[0]} to {range[1]}
                </span>
              ),

            }}
            className="table-container custom-table1"
            scroll={{ x: 'max-content' }}
          />
        </div>

        <Modal
          open={open}
          onCancel={closeModal}
          destroyOnClose={true}
          footer={null}
          maskClosable={false} // Prevent modal from
        >
          <div className="signup-content-model">
            <div className="heading-content">
              <h2>{type}</h2>
            </div>
            <div className="avatar-upload">
              {/* <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                />
                <label for="imageUpload"></label>
              </div>
              <div className="avatar-preview">
                {!imagePreview && <div id="imagePreview"></div>}
                {imagePreview && (
                  <div
                    style={{
                      backgroundImage: `url(${imagePreview})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      border: "2px solid #ddd",
                    }}
                  ></div>
                )}
              </div> */}
            </div>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={selectedRow || initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm); // Use your custom submit logic here
              }}
            >
              {({values, errors, touched, setFieldValue, resetForm }) => (
                <Form
                  id="signup-form"
                  className="signup-form container"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          autoComplete="off"
                          onChange={(e) =>
                            setFieldValue(
                              "firstName",
                              e.target.value.trimStart()
                            )
                          }
                          className={`form-input ${
                            errors.firstName && touched.firstName ? "error" : ""
                          }`}
                          name="firstName"
                          placeholder="First Name*"
                        />
                        <ErrorMessage name="firstName">
                          {(msg) => (
                            <span className="error-message">
                              <i className="fa-solid fa-circle-info fa-xl"></i>
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          autoComplete="off"
                          onChange={(e) =>
                            setFieldValue(
                              "lastName",
                              e.target.value.trimStart()
                            )
                          }
                          className={`form-input ${
                            errors.lastName && touched.lastName ? "error" : ""
                          }`}
                          name="lastName"
                          placeholder="Last Name*"
                        />
                        <ErrorMessage name="lastName">
                          {(msg) => (
                            <span className="error-message">
                              <i className="fa-solid fa-circle-info fa-xl"></i>
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          autoComplete="off"
                          className={`form-input ${
                            errors.phoneNumber && touched.phoneNumber
                              ? "error"
                              : ""
                          }`}
                          name="phoneNumber"
                          placeholder="Phone Number*"
                        />
                        <ErrorMessage name="phoneNumber">
                          {(msg) => (
                            <span className="error-message">
                              <i className="fa-solid fa-circle-info fa-xl"></i>
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          autoComplete="off"
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="email"
                          placeholder="Email*"
                          disabled={type!='Add User'}
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <span className="error-message">
                              <i className="fa-solid fa-circle-info fa-xl"></i>
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                 {type=='Add User' && <div className="row">
        <div className="col-md-6">
        <div className="form-group">
        <Field name="role"  className={`form-input ${
    errors.role && touched.role ? "error" : ""
  }`}>
  {({ field, form }) => (
    <Select
      style={{ width: 182, height: 55 }}
      {...field}
      options={[
        { value: 'Admin', label: 'Admin' },
        { value: 'Contributor', label: 'Contributor' },
        { value: 'Compliance Analyst', label: 'Compliance Analyst' },
      ]}

      onChange={(option) => {console.log(option);setFieldValue('role', option)}}
    />
  )}
</Field>
<ErrorMessage name="role" component="div" className="error-message" />


            {/* <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.paymentStatus}
                  options={[
                    { value: "Admin", label: "Admin" },
                    { value: "Contributor", label: "Contributor" },
                    { value: "Unpaid", label: "Unpaid" },
                  ]}
                  placeholder="Payment Status"
                  onChange={(value) =>
                    handleFilterChange("paymentStatus", value)
                  } // Update filter state
                /> */}
          </div>
        </div>
      </div>}
                  <div
                    className="submit-container1"
                    style={{ marginTop: "20px" }}
                  >
                    <button className="submit-button1 " type="submit">
                      Submit
                    </button>
                    <button



                      className="submit-button2"
                      type="button"
                      onClick={() => handleCancel(resetForm)}
                    >
                      Close
                    </button>
                  </div>

                  {/* Custom Cancel Button */}
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
        <Modal
          open={confirmModalOpen}
          onCancel={cancelToggle}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
        >
          <div className="signup-content-model1">
            <p>
              Are you sure you want to change the admin approval status for this
              user?
            </p>
            <div className="submit-container1" style={{ marginTop: "20px" }}>
              <button
                className="submit-button1 "
                type="submit"
                onClick={confirmToggle}
              >
                Yes
              </button>
              <button
                className="submit-button2"
                type="button"
                onClick={() => cancelToggle()}
              >
                No
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          open={openDeleteModal}
          onCancel={cancelDeleteToggle}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
        >
          <div className="signup-content-model1">
            <p>Are you sure to <b>delete</b> this record?</p>
            <div className="submit-container1" style={{ marginTop: "20px" }}>
              <button
                className="submit-button1 "
                type="submit"
                onClick={deleteUser}
              >
                Yes
              </button>
              <button
                className="submit-button2"
                type="button"
                onClick={() => cancelDeleteToggle()}
              >
                No
              </button>
            </div>
          </div>
        </Modal>



        <Modal
          open={openAprrovalModal}
          onCancel={cancelApprovalToggle}
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
        >
          <div className="signup-content-model1">
            <p>Are you sure to <b>{approvalType} </b> this record?</p>
            <div className="submit-container1" style={{ marginTop: "20px" }}>
              <button
                className="submit-button1 "
                type="submit"
                onClick={approvalUser}
              >
                Yes
              </button>
              <button
                className="submit-button2"
                type="button"
                onClick={() => cancelApprovalToggle()}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UserManagement;
