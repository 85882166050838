import React, { useState } from "react";
import "./ResetPassword.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link ,useParams } from 'react-router-dom';
import Axios from "../../Helpers/Interceptors";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from '../Loader/Loader';
import { getLogoName } from '../../Helpers/utils';
const ResetPassword = () => {
  const [loading, setloading] = useState(false);
  const [Logo, setLogo] = useState(getLogoName);
  const { id } = useParams();
  const { check } = useParams();
  const navigate = useNavigate();
  const [initialValues] = useState({
    password: "",
    confirmpassword: ""
  });

  const handleSubmit = async (values) => {
    let obj ={

      password:values.password,
      flag:check === 'isValid' ? true :false
    }

    try {
      setloading(true)
      const response = await Axios.post(`forgotPassword/changePassword/${id}`, obj);
      if (response.status === 200) {
        setloading(false)
        toast.success('Password has been updated!');
        navigate("/");
      } else if (response.status === 401 || response.status === 403) {
        setloading(false)
        toast.error(response.response.data.message);

      }
      toast.error( response?.response?.data);
      setloading(false)
    } catch (error) {
      console.error('Error:', error);

      // If error is a CORS issue, you might not have a response object
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
      } else if (error.request) {
        toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      } else {
        toast.error('An error occurred. Please try again later.');
      }

      setloading(false);
    }
    // Add your API call here
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters *")
      .max(20, "Password must not exceed 20 characters *")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter *")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter *")
      .matches(/[0-9]/, "Password must contain at least one number *")
      .matches(/[^A-Za-z0-9]/, "Password must contain at least one special character *")
      .matches(/^\S*$/, "Password should not contain spaces *")
      .required("Password is required *"),

    confirmpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords must match *")
      .required("Confirm Password is required *"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  return (
    <>
        { loading && <Loader />}
     <div className="main">
      <section className="signup">
        <div className="container-size">
          <div className="signup-content">
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              {({ errors, touched }) => (
                <Form id="signup-form" className="signup-form" autoComplete="off">
                  <div className="register-logo">
                  <div className="logo-resize">
                    <img
                      src="/images/doctor-logo1.png"
                      style={{ height: "65px" }}
                      alt="Logo"
                    />
                    {/* <h2 class="first-txt">{Logo}</h2> */}
                    </div>
                    <h2 style={{    color: '#b10606'}}>Reset Password</h2>
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <Field autoComplete="off"
                        className={`form-input ${errors.password && touched.password ? 'error' : ''}`}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password*"
                      />
                      <i
                        className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: 'pointer', marginLeft: '-30px', marginTop: '24px' }}
                      ></i>
                    </div>
                    <ErrorMessage name="password" component="span" className="error-message" />
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <Field autoComplete="off"
                        className={`form-input ${errors.confirmpassword && touched.confirmpassword ? 'error' : ''}`}
                        name="confirmpassword"
                        type={showPassword1 ? "text" : "password"}
                        placeholder="Confirm Password*"
                      />
                      <i
                        className={`far ${showPassword1 ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={() => setShowPassword1(!showPassword1)}
                        style={{ cursor: 'pointer', marginLeft: '-30px', marginTop: '24px' }}
                      ></i>
                    </div>
                    <ErrorMessage name="confirmpassword" component="span" className="error-message" />
                  </div>

                  <div className="form-group">
                    <button className="form-submit" type="submit">
                      Send
                    </button>
                  </div>

                  <p className="loginhere">
                    Have already an account?{" "}
                    <Link to="/" className="loginhere-link">
                      Login here
                    </Link>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
    </>

  );
};

export default ResetPassword;
